import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createWaterCheckAsync,
  getWaterChecks,
  getWaterChecksAsync,
  getWaterSuccess,
  resetWaterCheckList,
  resetSuccess,
  getIsLoading,
  waterCSV,
} from "../../../../features/watercheck/WaterCheckSlice";
import { getUserInfo } from "../../../../features/login/loginSlice";
import { Form } from "../../../UI/Form/Form";
import classes from "./WaterCheck.module.css";
import { ReadWaterCheck } from "./ReadWaterCheck/ReadWaterCheck";
import { toast } from "react-toastify";
import { toastifyConfig } from "../../../../features/toastifyConfig/toastifyConfig";
import { Button } from "../../../UI/Button/Button";
import { domain } from "../../../../features/apiConfig/apiConfig";

export const WaterCheck = () => {
  const dispatch = useDispatch();
  const waterChecks = useSelector(getWaterChecks);
  const [errorInformation, setErrorInformation] = useState({
    errorMessage: "",
    inputErrors: [
      { fieldName: "estate", error: "" },
      { fieldName: "value", error: "" },
      { fieldName: "contactPerson", error: "" },
      { fieldName: "email", error: "" },
    ],
  });
  const userInfo = useSelector(getUserInfo);
  const submitSuccess = useSelector(getWaterSuccess);
  const isLoading = useSelector(getIsLoading);
  const [formData, setFormData] = useState({
    estate: "",
    value: -1,
    contactPerson: "",
    email: "",
    turnOffWaterAtWinter: false,
  });
  const [resetForm, setResetForm] = useState(false);

  const formDefinition = [
    {
      type: "label",
      labelText: "Fastighet (endast siffror och kolon, t.ex 1:27 eller 1:100)",
    },
    {
      type: "text",
      handler: (event) => {
        setFormData((prev) => {
          return { ...prev, estate: event.target.value };
        });
      },
      fieldName: "estate",
    },
    { type: "label", labelText: "Avläst värde" },
    {
      type: "number",
      handler: (event) => {
        setFormData((prev) => {
          return { ...prev, value: event.target.value };
        });
      },
      fieldName: "value",
    },
    { type: "label", labelText: "Kontaktperson" },
    {
      type: "text",
      handler: (event) => {
        setFormData((prev) => {
          return { ...prev, contactPerson: event.target.value };
        });
      },
      fieldName: "contactPerson",
    },
    { type: "label", labelText: "Mejl för faktura" },
    {
      type: "text",
      handler: (event) => {
        setFormData((prev) => {
          return { ...prev, email: event.target.value };
        });
      },
      fieldName: "email",
    },
    {
      type: "label",
      labelText: "Vi vill <u>inte</u> ha vattnet avstängt på vintern",
    },
    {
      type: "checkbox",
      handler: (event) => {
        setFormData((prev) => {
          return { ...prev, turnOffWaterAtWinter: !prev.turnOffWaterAtWinter };
        });
      },
    },
  ];

  const buttonClickedHandler = (event) => {
    event.preventDefault();
    dispatch(resetSuccess());
    setResetForm(false);
    setErrorInformation({
      errorMessage: "",
      inputErrors: [
        { fieldName: "estate", error: "" },
        { fieldName: "value", error: "" },
        { fieldName: "contactPerson", error: "" },
        { fieldName: "email", error: "" },
      ],
    });

    if (!allDataSet()) {
      return;
    }
    dispatch(createWaterCheckAsync(formData));
  };

  const allDataSet = () => {
    let isValid = true;
    if (
      (parseInt(formData.value) && formData.value == -1) ||
      !parseInt(formData.value)
    ) {
      setErrorInformation((prev) => {
        const error = "Avläst värde får endast innehålla siffror 0 och uppåt";
        const inputError = (prev.inputErrors.filter(
          (x) => x.fieldName == "value"
        )[0].error = error);

        return {
          ...prev,
          inputErrors: [...prev.inputErrors, inputError],
        };
      });
      isValid = false;
    } else {
      const error = "";
      setErrorInformation((prev) => {
        const inputError = (prev.inputErrors.filter(
          (x) => x.fieldName == "value"
        )[0].error = error);

        return {
          ...prev,
          inputErrors: [...prev.inputErrors, inputError],
        };
      });
    }

    const estatePattern = /^[0-9]{1,3}:[0-9]{1,3}/;
    if (!estatePattern.test(formData.estate)) {
      setErrorInformation((prev) => {
        const error =
          "Fastighet anges endast med siffror och kolon, t.ex 1:27 eller 1:100).";
        const inputError = (prev.inputErrors.filter(
          (x) => x.fieldName == "estate"
        )[0].error = error);

        return {
          ...prev,
          inputErrors: [...prev.inputErrors, inputError],
        };
      });
      isValid = false;
    } else {
      const error = "";
      setErrorInformation((prev) => {
        const inputError = (prev.inputErrors.filter(
          (x) => x.fieldName == "estate"
        )[0].error = error);

        return {
          ...prev,
          inputErrors: [...prev.inputErrors, inputError],
        };
      });
    }

    if (formData.contactPerson === "") {
      const error = "Kontaktperson får inte vara tomt.";
      setErrorInformation((prev) => {
        const inputError = (prev.inputErrors.filter(
          (x) => x.fieldName == "contactPerson"
        )[0].error = error);

        return {
          ...prev,
          inputErrors: [...prev.inputErrors, inputError],
        };
      });
      isValid = false;
    } else {
      const error = "";
      setErrorInformation((prev) => {
        const inputError = (prev.inputErrors.filter(
          (x) => x.fieldName == "contactPerson"
        )[0].error = error);

        return {
          ...prev,
          inputErrors: [...prev.inputErrors, inputError],
        };
      });
    }

    const emailPattern = /^[a-z0-9.]+@[a-z0-9.]+\.[a-z]+$/;
    if (!emailPattern.test(formData.email)) {
      const error = "Mejl för faktura, en giltlig epostadress saknas.";
      setErrorInformation((prev) => {
        const inputError = (prev.inputErrors.filter(
          (x) => x.fieldName == "email"
        )[0].error = error);

        return {
          ...prev,
          inputErrors: [...prev.inputErrors, inputError],
        };
      });
      isValid = false;
    } else {
      const error = "";
      setErrorInformation((prev) => {
        const inputError = (prev.inputErrors.filter(
          (x) => x.fieldName == "email"
        )[0].error = error);

        return {
          ...prev,
          inputErrors: [...prev.inputErrors, inputError],
        };
      });
    }

    if (!isValid) {
      setErrorInformation((prev) => {
        return {
          ...prev,
          errorMessage: "Kontrollera att formuläret är korrekt ifyllt.",
        };
      });
    }
    return isValid;
  };

  useEffect(() => {
    dispatch(getWaterChecksAsync());
  }, []);

  useEffect(() => {
    if (submitSuccess) {
      toast.success("Din avläsning är nu skickad", toastifyConfig);
      setFormData({
        estate: "",
        value: -1,
        contactPerson: "",
        email: "",
        turnOffWaterAtWinter: false,
      });
      dispatch(getWaterChecksAsync());
      dispatch(resetWaterCheckList());
      dispatch(resetSuccess());
      setResetForm(true);
    }
  }, [submitSuccess, waterCSV]);

  useEffect(() => {}, [resetForm]);

  useEffect(() => {
    errorInformation.errorMessage.length !== 0 &&
      toast.error(errorInformation.errorMessage, toastifyConfig);
  }, [errorInformation]);

  useEffect(() => {}, [errorInformation.inputErrors]);

  return (
    <div
      className={`${
        userInfo.users_priviledge === "admin"
          ? classes.WaterCheckAdmin
          : classes.WaterCheck
      }`}
    >
      <div>
        <h1>Vattenavläsning</h1>
        <p>
          Här kan du registrera den vattenförbrukning som har skett det senaste
          året. Därtill kan du i samband med detta ange om ni önskar vattnet ej
          stängs av av föreningen för vintern.
          <br />
          <br />
          <strong style={{ color: "red" }}>OBS: </strong>
          <strong>
            Fastighetsägaren skall tydligt märka upp ventilerna inför
            stängningen.
          </strong>
        </p>
        <Form
          formDefinition={formDefinition}
          buttonText="Skicka in din avläsning"
          clicked={(event) => {
            buttonClickedHandler(event);
          }}
          isLoading={isLoading}
          reset={resetForm}
          value={formData.turnOffWaterAtWinter}
          inputErrors={errorInformation.inputErrors}
        ></Form>
      </div>
      {userInfo.users_priviledge === "admin" && (
        <div>
          <h1>Avläsningar</h1>
          {waterChecks.length === 0 && (
            <p>Inga avläsningar finns inrapporterade än</p>
          )}
          {waterChecks.length !== 0 && (
            <a className={classes.ExportCSV} href={`${domain}watercheck/excel`}>
              <Button>Exportera till EXCEL</Button>
            </a>
          )}
          <div className={classes.WaterCheckList}>
            {waterChecks.map((wCheck, i) => {
              return (
                <>
                  <ReadWaterCheck
                    key={`${i}-${wCheck.water_check_id}`}
                    estate={wCheck.water_check_estate}
                    value={wCheck.water_check_value}
                    created={wCheck.water_check_created}
                  />
                </>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
